import React, { useState, useRef, useEffect } from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "./WhiteSliderBlock.css"

// Function to calculate dynamic heights based on line count
const calculateHeights = lineCount => {
  // Base values
  const baseDescriptionHeight = 90 // px - minimum height for description
  const baseCardHeight = 360 // px - minimum height for card

  // Additional height per line
  const heightPerLine = 24 // px per line

  // Calculate heights
  const descriptionHeight = baseDescriptionHeight + lineCount * heightPerLine
  const cardHeight = baseCardHeight + lineCount * heightPerLine

  return {
    descriptionHeight: `${descriptionHeight}px`,
    cardHeight: `${cardHeight}px`,
  }
}

// Function to estimate line count based on text
const estimateLineCount = (text, charsPerLine = 60) => {
  // Count actual line breaks
  const newlineCount = (text.match(/\n/g) || []).length
  // Estimate based on character count
  const charBasedLines = Math.ceil(text.length / charsPerLine)
  // Use whichever is larger plus a little buffer to avoid text overflow
  return Math.max(2, Math.max(newlineCount, charBasedLines) + 1)
}

function AiSliderComponent({ number, title, description, lineCount }) {
  // Calculate heights based on line count
  const { descriptionHeight, cardHeight } = calculateHeights(lineCount)

  return (
    <div
      className="AIcard relative overflow-hidden bg-white flex flex-col"
      style={{ height: cardHeight, backgroundColor: "white" }}
    >
      <div className="flex flex-col items-start h-full relative z-10">
        <div
          className="w-16 h-16 md:w-20 md:h-20 lg:w-24 lg:h-24 rounded-3xl flex items-center justify-center mb-4 md:mb-6 text-2xl md:text-3xl lg:text-4xl"
          style={{
            background: "rgba(51, 95, 255, 0.10)",
            color: "#335FFF",
            fontWeight: "bold",
          }}
        >
          {String(number).padStart(2, "0")}
        </div>
        <h3 className="text-lg md:text-2xl lg:text-3xl font-bold text-[#3D4048] mb-3 md:mb-4 break-words w-full h-16">
          {title}
        </h3>
        <p
          className="text-sm md:text-base lg:text-base  text-[#3D4048] leading-relaxed w-full p-4 rounded-md"
          style={{
            height: descriptionHeight,
            overflow: "hidden",
            backgroundColor: "#ebebeb",
          }}
        >
          {description}
        </p>
      </div>
    </div>
  )
}

export default function WhiteSliderBlock({ WhiteSliderCard }) {
  const [currentSlide, setCurrentSlide] = useState(0)
  const sliderRef = useRef(null)

  console.log("WhiteSliderCard prop:", WhiteSliderCard)

  // Handle both potential data structures
  let sliderValues = []

  // If data comes in the format from original code
  if (WhiteSliderCard?.whiteSliderCard) {
    sliderValues =
      WhiteSliderCard?.whiteSliderCard?.[0]?.WhiteSliderCPT
        ?.whiteSliderValues || []
    console.log("Using nested structure:", sliderValues)
  }
  // If data comes directly as an array
  else if (Array.isArray(WhiteSliderCard)) {
    sliderValues = WhiteSliderCard[0]?.WhiteSliderCPT?.whiteSliderValues || []
    console.log("Using array structure:", sliderValues)
  }
  // If data is provided directly
  else {
    sliderValues = WhiteSliderCard?.[0]?.WhiteSliderCPT?.whiteSliderValues || []
    console.log("Using direct structure:", sliderValues)
  }

  // Process items to add line count
  const items = sliderValues?.map(item => ({
    number: item?.number,
    title: item?.title,
    description: item?.description,
    lineCount: estimateLineCount(item?.description),
  }))

  // Find the maximum line count or use a default if no items
  const maxLineCount =
    items?.length > 0 ? Math.max(...items?.map(item => item?.lineCount)) : 3 // Default line count if no items

  // Calculate uniform heights for all cards based on the max line count
  const { cardHeight } = calculateHeights(maxLineCount)

  // Add CSS for the description containers to ensure proper styling
  useEffect(() => {
    const styleTag = document.createElement("style")
    styleTag.innerHTML = `
      .AIcard {
        height: ${cardHeight} !important;
        margin-bottom: 15px;
      }
      
      /* Ensure slides have equal height */
      .slick-track {
        display: flex !important;
      }
      
      .slick-slide {
        height: inherit !important;
      }
      
      .slick-slide > div {
        height: 100%;
      }
    `
    document.head.appendChild(styleTag)

    return () => {
      document.head.removeChild(styleTag)
    }
  }, [cardHeight])

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    autoplay: false,
    autoplaySpeed: 3000,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      { breakpoint: 1024, settings: { slidesToShow: 1, slidesToScroll: 1 } },
      { breakpoint: 600, settings: { slidesToShow: 1, slidesToScroll: 1 } },
      { breakpoint: 480, settings: { slidesToShow: 1, slidesToScroll: 1 } },
    ],
    beforeChange: (current, next) => setCurrentSlide(next),
  }

  // Debug what items we have
  console.log("Processed items:", items)

  return (
    <div className="relative w-full bg-[#F0F4FF] overflow-hidden">
      <div className="relative max-w-screen-2xl mx-auto px-4 py-16">
        <div className="relative md:px-8 md:py-12">
          <Slider ref={sliderRef} {...settings} className="gap-slider">
            {items?.map((item, index) => (
              <AiSliderComponent
                key={index}
                number={item.number}
                title={item.title}
                description={item.description}
                lineCount={maxLineCount} // Use max line count for uniform height
              />
            ))}
          </Slider>

          <div className="flex justify-center items-center gap-4 mt-8">
            <button
              onClick={() => sliderRef.current?.slickPrev()}
              className="w-12 h-12 rounded-full border bg-transparent flex items-center justify-center hover:bg-white/10 transition-colors"
              style={{ border: "1px solid #335FFF" }}
            >
              <i
                className="icon-slider-left-arrow flex justify-center py-3 mx-0 active:text-blue-500"
                style={{ fontSize: "20px", color: "#335FFF" }}
              />
            </button>
            <span className="text-lg font-bold" style={{ color: "#335FFF" }}>
              {currentSlide + 1} /{" "}
              <span
                className="text-lg font-bold opacity-50"
                style={{ color: "#335FFF" }}
              >
                {items?.length}
              </span>
            </span>
            <button
              type="button"
              onClick={() => sliderRef.current?.slickNext()}
              className="w-12 h-12 rounded-full border border-[#335FFF] bg-transparent flex items-center justify-center hover:bg-white/10 transition-colors"
              style={{ border: "1px solid #335FFF" }}
            >
              <i
                className="icon-slider-right-arrow flex justify-center py-3 mx-0 active:text-blue-500"
                style={{ fontSize: "20px", color: "#335FFF" }}
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
